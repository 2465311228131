import { CheckOk } from '@/icons';

import type { FunctionComponent } from 'react';
import type { CheckBoxAtomProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const CheckboxAtom: FunctionComponent<CheckBoxAtomProps> = ({
  checked = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setValue = () => {},
  disabled = false,
  className = '',
  testid,
  ...props
}: CheckBoxAtomProps) => (
  <div
    onClick={() => setValue()}
    className={`mr-2 flex h-4 w-4 flex-shrink-0 cursor-pointer items-center justify-center border border-primary-600 aria-disabled:pointer-events-none ${className}`}
    {...(disabled && { 'aria-disabled': true })}
    {...(testid && { 'data-playwright': `chx_${testid}` })}
    {...props}
  >
    {checked ? <CheckOk /> : null}
  </div>
);
