import { BreadcrumbJsonLd } from 'next-seo';

import { ChevronRight } from '@/icons';
import { NextLink } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeBreadcrumbsProps } from './types';

/**
 * Breadcrumbs
 */
export const Breadcrumbs: FunctionComponent<TypeBreadcrumbsProps> = ({
  className = '',
  breadcrumbs,
  mobileIndex = 1,
}: TypeBreadcrumbsProps) => (
  <>
    <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Inicio',
          item: process.env.NEXT_PUBLIC_FRONT_URL,
        },
        ...breadcrumbs
          .slice(1, breadcrumbs.length)
          .map((breadcrumb, index) => ({
            position: index + 2,
            name: breadcrumb.name,
            item: `${process.env.NEXT_PUBLIC_FRONT_URL}${breadcrumb.url}`,
          })),
      ]}
    />

    <div
      className={`u-actions u-actions--breadcrumbs flex flex-row items-center gap-2 py-1 text-typo-primary ${className}`}
    >
      {breadcrumbs.map((breadcrumb, index, array) => {
        const mobileVisible =
          array.length > 1 && index + 1 === array.length - mobileIndex;

        return (
          <div
            key={`breadcrumb_${index}`}
            className={`flex flex-row items-center gap-2 ${
              !mobileVisible ? 'max-lg:hidden' : ''
            }`}
          >
            <ChevronRight
              width={12}
              height={12}
              className="shrink-0 max-lg:rotate-180 lg:hidden"
            />

            {breadcrumbs.length - 1 > index ? (
              <NextLink href={breadcrumb.url}>
                <a
                  className={`${
                    index + 1 === array.length
                      ? 'line-clamp-1'
                      : 'whitespace-nowrap'
                  }`}
                >
                  {breadcrumb.name}
                </a>
              </NextLink>
            ) : (
              <p
                className={`${
                  index + 1 === array.length
                    ? 'line-clamp-1'
                    : 'whitespace-nowrap'
                }`}
              >
                {breadcrumb.name}
              </p>
            )}

            {breadcrumbs.length - 1 > index && (
              <ChevronRight
                width={12}
                height={12}
                className="shrink-0 max-lg:hidden max-lg:rotate-180"
              />
            )}
          </div>
        );
      })}
    </div>
  </>
);

Breadcrumbs.displayName = 'Breadcrumbs';
